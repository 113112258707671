<template>
  <el-dialog
    title="電商模組消費回饋設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem label="是否啟用">
        <BaseElSwitch
          v-model="formData.enable"
          active-text="開啟"
          inactive-text="關閉"
        />
      </BaseElFormItem>
      <BaseElFormItem label="電商商品回饋比率（%）" prop="ratio">
        <BaseElInput v-model="formData.ratio" placeholder="請輸入百分比">
          <div slot="suffix">%</div>
        </BaseElInput>
      </BaseElFormItem>
      <BaseElFormItem label="無法獲得回饋的電商商品設定">
        <ProductSelect :model.sync="formData.products" multiple />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton
        ref="createBtn"
        v-loading="loading"
        type="primary"
        @click="updateConfig"
      >
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import ProductSelect from '@/components/Search/ecommerce/ProductSelect.vue'
import { noEmptyRules, rangeRules, isDigitRules } from '@/validation/index'
import { UpdateShopRebateEcommerce } from '@/api/rebate'
import formUtils from '@/utils/form'
import { map } from 'lodash'
import store from '@/store'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(['close', 'refresh'])
const shopId = computed(() => store.getters.shop)
const loading = ref(false)
const formRef = ref(null)
const formData = reactive({
  enable: false,
  ratio: 0,
  products: [],
})
const formRules = {
  ratio: [noEmptyRules(), rangeRules(0, 100), isDigitRules()],
}
const options = [
  { value: 30 },
  { value: 60 },
  { value: 90 },
  { value: 180 },
  { value: 360 },
]

const updateConfig = async () => {
  if (loading.value) return
  loading.value = true
  if (!(await formUtils.checkForm(formRef.value))) {
    loading.value = false
    return
  }
  const [, err] = await UpdateShopRebateEcommerce({
    shopId: shopId.value,
    enabled: formData.enable,
    addRate: formData.ratio / 100,
    ignoreAddEcommerceProducts: map(formData.products, 'id'),
  })
  loading.value = false
  if (err) return (window.$message.error(err))
  window.$message.success('更新成功！')
  emit('refresh')
  emit('close')
}

const syncData = async () => {
  formData.enable = props.data.enabled
  formData.ratio = props.data.addRate * 100
  formData.products = props.data.IgnoreAddEcommerceProducts
}

onMounted(() => {
  syncData()
})
</script>

<style scoped lang="postcss">

</style>
