<template>
  <main class="rebate-settings">
    <PageTitle
      title="回饋金參數設定"
      icon="chevron_left"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <BaseElForm v-if="!get(shopRebate, 'id')" label-position="left" label-width="180px">
      <BaseElFormItem label="是否開啟回饋金模組：">
        <BaseElButton v-loading="loading" type="primary" @click="registerShopRebate">開啟</BaseElButton>
      </BaseElFormItem>
    </BaseElForm>

    <template v-if="get(shopRebate, 'id')">
      <RebateTimeSettings />
      <EcommerceSalesRebateSettings v-if="checkAction('admin.ecommerceCashback.page') && checkAction('admin.ecommercePage.page')" />
      <SalesRebateSettings v-if="checkAction('admin.salesCashback.page') && checkAction('admin.salesProduct.page')" />
      <RebateDiscount v-if="checkAction('admin.ecommercePage.page')" />
    </template>
  </main>
</template>

<script>
import RebateTimeSettings from './components/RebateTimeSettings.vue'
import EcommerceSalesRebateSettings from './components/EcommerceSalesRebateSettings.vue'
import SalesRebateSettings from './components/SalesRebateSettings.vue'
import RebateDiscount from './components/RebateDiscount.vue'
import { FindShopRebate, RegisterShopRebate } from '@/api/rebate'
import { onMounted, computed, ref, defineComponent } from 'vue'
import { usePermissions } from '@/use/permissions'
import { get } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'RebateSettings',
  components: {
    RebateTimeSettings,
    EcommerceSalesRebateSettings,
    SalesRebateSettings,
    RebateDiscount,
  },
  setup () {
    const { checkAction } = usePermissions()
    const shopId = computed(() => store.getters.shop)
    const shopRebate = ref(null)
    const loading = ref(false)

    const findShopRebate = async () => {
      const [res, err] = await FindShopRebate({ shopId: shopId.value })
      shopRebate.value = res
    }

    const registerShopRebate = async () => {
      if (loading.value) return
      loading.value = true
      const [, err] = await RegisterShopRebate({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      await findShopRebate()
      loading.value = false
    }

    onMounted(async () => {
      loading.value = true
      await findShopRebate()
      loading.value = false
    })

    return {
      checkAction,
      shopRebate,
      registerShopRebate,
      loading,
      get
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
