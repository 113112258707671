<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="電商模組消費回饋設定" @edit="dialog.edit = true" />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="是否啟用">
        <div class="secret-text">
          <span>{{ configData.enabled ? '啟用' : '關閉' }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="電商商品回饋比率">
        <div class="secret-text">
          <span>{{ Number.isNaN(rebateRatio) ? '尚未設定' : `${rebateRatio}%` }}</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="無法獲得回饋的電商商品設定">
        <div class="secret-text">
          <span>{{ ignoreRebateProducts }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <EditEcommerceSalesRebateSettings v-if="dialog.edit" :data="configData" @close="dialog.edit = false" @refresh="refresh" />
  </section>
</template>

<script setup>
import { reactive, onMounted, ref, computed } from 'vue'
import EditEcommerceSalesRebateSettings from './EditEcommerceSalesRebateSettings.vue'
import { FindShopRebateEcommerce } from '@/api/rebate'
import store from '@/store'
import { map } from 'lodash'

const configData = ref({})
const loading = ref(false)
const dialog = reactive({
  edit: false,
})
const formData = reactive({
  ratio: 0,
  products: [],
})
const shopId = computed(() => store.getters.shop)
const rebateRatio = computed(() => {
  return configData.value.addRate * 100
})
const ignoreRebateProducts = computed(() => {
  const products = map(configData.value.IgnoreAddEcommerceProducts, 'name')
  if (!products) return '尚未設定'
  if (!products.length) return '尚未設定'
  return products.join('、')
})

const findConfig = async () => {
  const [res, err] = await FindShopRebateEcommerce({ shopId: shopId.value })
  if (err) window.$message.error(err)
  configData.value = res
}

const refresh = async () => {
  loading.value = true
  console.log({ shopId: shopId.value })
  await findConfig()
  loading.value = false
}

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
