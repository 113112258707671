<template>
  <section class="section-block" v-loading="loading">
    <SectionTitle title="回饋金消費折抵設定" hideBtn />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="是否可與電商優惠同時使用">
        <GroupOptionsButton
          :model.sync="configData.useIgnoreAllActivity"
          :options="[
            {label: '可同時使用', value: false},
            {label: '不可同時使用', value: true},
          ]"
          @change="updateConfig"
        />
      </BaseElFormItem>
    </BaseElForm>
  </section>
</template>

<script setup>
import GroupOptionsButton from '@/components/Button/GroupOptionsButton.vue'
import { FindShopRebateEcommerce, UpdateShopRebateEcommerce } from '@/api/rebate'
import { computed, ref, onMounted } from 'vue'
import store from '@/store'

const shopId = computed(() => store.getters.shop)
const loading = ref(false)
const configData = ref({})

const updateConfig = async () => {
  if (loading.value) return
  loading.value = true
  const [, err] = await UpdateShopRebateEcommerce({
    shopId: shopId.value,
    useIgnoreAllActivity: configData.value.useIgnoreAllActivity,
  })
  loading.value = false
  if (err) return (window.$message.error(err))
  window.$message.success('更新成功！')
  await refresh()
}

const findConfig = async () => {
  const [res, err] = await FindShopRebateEcommerce({ shopId: shopId.value })
  if (err) window.$message.error(err)
  configData.value = res
}

const refresh = async () => {
  loading.value = true
  console.log({ shopId: shopId.value })
  await findConfig()
  loading.value = false
}

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
