<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="回饋金期限設定" @edit="dialog.edit = true" />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem label="有效期限">
        <div class="secret-text">
          <span>{{ configData.daysUntilExpiration || '尚未設定' }} 天</span>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="到期通知發送天數">
        <div class="secret-text">
          <span>{{ configData.daysDiffExpirationNotify || '尚未設定' }} 天</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <EditRebateTimeSettings v-if="dialog.edit" :data="configData" @close="dialog.edit = false" @refresh="refresh" />
  </section>
</template>

<script setup>
import { reactive, computed, onMounted, ref } from 'vue'
import EditRebateTimeSettings from './EditRebateTimeSettings.vue'
import { FindShopRebateYearExpiration } from '@/api/rebate'
import store from '@/store'

const shopId = computed(() => store.getters.shop)
const configData = ref({})
const loading = ref(false)
const dialog = reactive({
  edit: false,
})

const findShopRebateYearExpiration = async () => {
  const [res, err] = await FindShopRebateYearExpiration({
    shopId: shopId.value,
  })
  if (err) window.$message.error(err)
  configData.value = res
}

const refresh = async () => {
  loading.value = true
  await findShopRebateYearExpiration()
  loading.value = false
}

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
